import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import './about.css'
import Seo from "../../components/seo"

const About = (props) => {

  return (
    <>
    <Seo title="About" description="On this page you can read all about me! About my studies and my experience as a oboe player and a oboe teacher." />
    <Layout>
     
      <section className="main-about">
          <div className="left">
            <StaticImage 
              src="../../images/5489.jpg"
              alt="Quentin" >
            </StaticImage>
          </div>
          <div className="right">
             <h2 className="sub-title">About me</h2>
            <p>I was born in 1991 in Geneva. My father was an enthusiastic amateur oboist and our home always resonated with music. I would often accompany him to rehearsals and concerts. My passion for music was born then. At the age of five, I discovered the piano; I was looking to find notes that sounded well together which prompted my parents to let me have “rythmique-solfège” lessons following the Jaques-Dalcroze method; soon after I got my first piano lessons, with Pascal Chenu and Xavier Parès. I quickly learned how to write the melodies I was composing. I was a prize winner at the 2004 Composition Competition held through the music schools of Geneva.</p>
            <p>I always felt the urge to play together with others. As a pianist, I often accompanied instrumentalists or vocalists, but I wanted even more than that. That was one of the reasons for me to take up the oboe at age fifteen in the class of Béatrice Zawodnik. My goal was to be able to be part of an orchestra as soon as possible. After a couple of years, I joined the Orchestre Saint-Pierre Fusterie, the Sinfonietta de Genève and the Orchestre du Collège de Genève.</p>
            <p>Meanwhile, my compositions became more ambitious. 2011 saw the première of my Requiem, scored for soprano, choir, and orchestra.</p>
            <p>In 2012, I was admitted to the Utrechts Conservatorium to study oboe with Ernest Rombout. I graduated in 2017. After a premaster at the Conservatorium van Amsterdam in the class of Miriam Pastor Burgos, I completed in July 2021 a master’s degree at Codarts in Rotterdam with Hans Wolters and Maarten Dekkers as my teachers.</p>
            <p>As a musician, I have always had very diverse interests: I am not only an oboist and a pianist, I have also sung in choirs and played basso continuo; I am a teacher and a performer; I have played in diverse orchestras such as the Radio Filharmonisch Orkest, the Residentie Orkest, the Metropole Orkest, or the Koninklijke Militaire Kapel Johan Willem Friso, and in chamber music ensembles from two to thirteen players; I’m a composer and an arranger. This eclecticism is very important to me, and I strive to share that with others.</p>
            <p>I play on a Marigaux 901 oboe and a Mönnig 180 RW Cor Anglais. I have been lucky to be granted funding bij Stichting Eigen Muziekinstrument for the purchase of both instruments.</p>
            </div>
      </section>
    </Layout>
    </>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`